import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";
import Link from "gatsby-link";
import get from "lodash/get";
import Img from "gatsby-image";
import $ from "jquery";
import "react-photoswipe/lib/photoswipe.css";
import Layout from "../components/Layout";
import Logo from "../components/Logo";
import Navigation from "../components/NavigationWithLogo";

import { PhotoSwipeGallery } from "react-photoswipe";

// import PhotoSwipeElement from '../components/PhotoswipeElement'
// import GalleryScript from '../script/galleryScript'

class BlogPostTemplate extends React.Component {
  state = {
    isOpen: false,
    items: [],
    options: {
      closeOnScroll: false,
    },
  };

  getThumbnailContent = (item) => (
    <img
      src={item.thumbnail}
      style={{ height: "150px", width: "auto" }}
      alt=""
    />
  );

  openPhotoSwipe = (e) => {
    e.preventDefault();
    this.setState({
      isOpen: true,
    });
  };

  handleClose = () => {
    this.setState({
      isOpen: false,
    });
  };

  render() {
    const post = this.props.data.contentfulProject;
    // console.log('Post: ', post)

    const siteTitle = get(this.props, "data.site.siteMetadata.title");
    const { previous, next } = this.props.pageContext;
    var images = [];
    var contentWithoutImages;
    const { options, isOpen } = this.state;

    function featuredMedia(postNode) {
      if (postNode) {
        if (postNode.thumbnail !== null && postNode.thumbnail !== undefined) {
          return (
            <img
              style={{ maxWidth: "100%" }}
              src={postNode.thumbnail.file.url}
            />
          );
        } else {
          return (
            <div
              className="fakeThunbnail"
              style={{ width: 0, height: 0, margin: 0, padding: 0 }}
            ></div>
          );
        }
      }
    }

    return (
      <Layout>
        <Logo />
        <Navigation menuText={post.title} />

        <div className="regular-container">
          <Helmet title={`${post.title} | ${siteTitle}`} />
          <section className="section-projekte">
            <div className="content">
              <article>
                <p style={{ fontStyle: "italic" }}>
                  {post.kategorie?.[0].title ?? ""}
                </p>

                <h1 style={{ marginBottom: 0 }}>{post.title}</h1>
                <h2
                  style={{ margin: 0 }}
                  dangerouslySetInnerHTML={{
                    __html: post?.subtitle?.childMarkdownRemark?.html ?? null,
                  }}
                ></h2>
                {featuredMedia(post)}
                <div className="section-content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.text.childMarkdownRemark.html,
                    }}
                  />
                  {post.gallery && (
                    <PhotoSwipeGallery
                      items={post.gallery?.map((image) => {
                        return {
                          src: image?.file?.url ?? null,
                          w: image?.file?.details?.image?.width ?? null,
                          h: image?.file?.details?.image?.height ?? null,
                          thumbnail: image?.fixed?.src ?? null,
                          // thumbnailSize: thumbnailSize,
                        };
                      })}
                      options={options}
                      thumbnailContent={this.getThumbnailContent}
                      isOpen={isOpen}
                      onClose={this.handleClose}
                      className="the-gallery"
                    />
                  )}
                </div>
              </article>
              <ul
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  listStyle: "none",
                  padding: 0,
                }}
              >
                {previous && (
                  <li>
                    <h2>
                      <Link to={"/projekte/" + previous.slug} rel="prev">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: "← " + previous.title,
                          }}
                        ></span>
                      </Link>
                    </h2>
                  </li>
                )}

                {next && (
                  <li>
                    <h2>
                      <Link to={"/projekte/" + next.slug} rel="next">
                        <span
                          dangerouslySetInnerHTML={{
                            __html: next.title + "  →",
                          }}
                        ></span>
                      </Link>
                    </h2>
                  </li>
                )}
              </ul>
            </div>
          </section>
        </div>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
  query Projekt($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    contentfulProject(slug: { eq: $slug }) {
      id
      text {
        childMarkdownRemark {
          html
        }
      }
      subtitle {
        childMarkdownRemark {
          html
        }
      }
      thumbnail {
        file {
          url
        }
      }
      gallery {
        file {
          details {
            image {
              height
              width
            }
          }
          url
        }
        fixed(height: 300) {
          src
        }
      }
      title
      date(formatString: "dddd, DD. MMMM YYYY", locale: "de")
      kategorie {
        title
      }
    }
  }
`;
